import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as markerjs2 from 'markerjs2';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-annotation',
  templateUrl: './annotation.component.html',
  styleUrls: ['./annotation.component.scss']
})
export class AnnotationComponent implements OnInit {
  image: any;
  popup_img: any;
  dynamicForm: FormGroup;
  d: any;
  damage_property_type1: any = [];
  submitted: boolean;
  Internal_damage_type1: any = [];
  data_list: any;
  get f() { return this.dynamicForm.controls; }
  get t() { return this.f.annitation_report as FormArray; }

  body_part_List = [
    { bodylist_Name: 'Bumper Front' },
    { bodylist_Name: 'Bumper Rear' },
    { bodylist_Name: 'Bumper End' },
    { bodylist_Name: 'Bumper Reflector' },
    { bodylist_Name: 'Bumper - Parking Aid Sensors' },
    { bodylist_Name: 'Cowl Panel' },
    { bodylist_Name: 'Door' },
    { bodylist_Name: 'Door Skin' },
    { bodylist_Name: ' Door Protector/Moulding' },

    { bodylist_Name: 'Exhaust Pipe (Muffler)' },
    { bodylist_Name: 'Engine Bottom Shield' },
    { bodylist_Name: 'Fender Extension Panel (Bumper Bracket)' },
    { bodylist_Name: 'Fog Lamp' },
    { bodylist_Name: 'Fender' },
    { bodylist_Name: 'Fender Flare' },
    { bodylist_Name: 'Fuel Lid' },
    { bodylist_Name: 'Head Lamp' },
    { bodylist_Name: 'Hood' },
    { bodylist_Name: 'Header Panel (Front Of Radiator Panel)' },
    { bodylist_Name: 'Inner Fender (Fender Liner)' },
    { bodylist_Name: 'Monogram' },
    { bodylist_Name: 'Mud Flap' },
    { bodylist_Name: 'Number Plate Garnish' },
    { bodylist_Name: 'Quarter Panel (Rear)' },


    { bodylist_Name: 'Rocker Panel  (Door Sill Panel)' },
    { bodylist_Name: 'Running Board (Foot Step)' },
    { bodylist_Name: 'Roof Panel' },
    { bodylist_Name: 'Rear Panel - Trunk' },
    { bodylist_Name: 'Show Grill' },
    { bodylist_Name: 'Signal Lamp' },
    { bodylist_Name: 'Side View Mirror' },
    { bodylist_Name: 'Side View Mirror Light' },
    { bodylist_Name: 'Tyre' },
    { bodylist_Name: 'Tail Door' },
    { bodylist_Name: 'Trunk Lid' },
    { bodylist_Name: 'Trunk Top Mount Lamp' },
    { bodylist_Name: 'Tail Lamp' },
    { bodylist_Name: 'Valance Panel (Bumper Spoiler)' },
    { bodylist_Name: 'Wind Shield Front' },
    { bodylist_Name: 'Wheel Arch Panel' },
    { bodylist_Name: 'Wheel Arch Spoiler' },
    { bodylist_Name: 'Wheel Rim' },
    { bodylist_Name: 'Wheel Cap' },
    { bodylist_Name: 'Wind Shield Rear' },
    { bodylist_Name: 'Others' },
  ];
  body_partlist: any = [];

  damage_type2: any = [];

  damage_Severity_type1: any = [];

  damage_type1: any = [

    { jobtype_Name: 'Broken' },

    { jobtype_Name: 'Dent - Small' },

    { jobtype_Name: 'Dent - Large' },

    { jobtype_Name: 'Dislocation or separation of parts' },

    { jobtype_Name: 'Missing Part' },

    { jobtype_Name: 'Rust' },

    { jobtype_Name: 'Spot' },

    { jobtype_Name: 'Scratch' },

    { jobtype_Name: 'Tear' },

  ];


  Label_Name: any;
  damage_Severity_type = [{ Severity_Name: 'High' }, { Severity_Name: 'Medium' }, { Severity_Name: 'Low' }];

  Internal_damage_type = [{ Severity_Name: 'Yes' }, { Severity_Name: 'No' }];

  damage_property_type = [{ Severity_Name: 'Minor Repair', value: 'R1' }, { Severity_Name: 'Major Repair', value: 'R2' }, { Severity_Name: 'Replace', value: 'R3' }];

  constructor(private dialog: MatDialog,
    public dialogRef: MatDialogRef<AnnotationComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public CommonService: CommonService,
    private Formbuilder: FormBuilder,
  ) {
    window.localStorage.removeItem('annotation_html');
    let name = this.data['img'].split('@@@')[0];
  }
  ngOnInit(): void {
    window.localStorage.removeItem('annotation_html');
    this.CommonService.loadRAutocomplete('https://unpkg.com/markerjs2/markerjs2.js')
    let name = this.data['img'].split('@@@')[0];
    this.popup_img = 'https://devomanapi.symplfy.in/uploadimage/' + name;
    this.dynamicForm = this.Formbuilder.group({
      annitation_report: new FormArray([])
    });
    // ------------------------------------------
    this.body_partlist = this.body_part_List.map((s: any) => ({
      id: s.bodylist_Name,
      Usertype: s.bodylist_Name,
    }))
    // ------------------------------------------
    this.damage_Severity_type1 = this.damage_Severity_type.map((s: any) => ({
      id: s.Severity_Name,
      Usertype: s.Severity_Name,
    }))
    this.damage_type2 = this.damage_type1.map((s: any) => ({
      id: s.jobtype_Name,
      Usertype: s.jobtype_Name,
    }));

    this.Internal_damage_type1 = this.Internal_damage_type.map((s: any) => ({
      id: s.Severity_Name,
      Usertype: s.Severity_Name,
    }))
    // --------------------------------------------------
    this.damage_property_type1 = this.damage_property_type.map((s: any) => ({
      id: s.value,
      Usertype: s.Severity_Name,
    }))
    // ------------------------------------------------------------------
    this.tickets();
    // ---------------------------------------------


  }

  tickets() {
    this.t.push(this.Formbuilder.group({
      body_part: [null],
      damage_type: [null],
      damage_serverity: [null],
      damage_property: [null],
      internal_damage: [null],
    }));
  }

  showMarkerArea(target) {
    const markerArea = new markerjs2.MarkerArea(target);
    markerArea.addEventListener("render", (event) => (target.src = event.dataUrl));
    markerArea.show();
  }

  OnSubmit_list() {
    this.submitted = true;
    const frameContent = document.getElementById("zmap");
    const iWindow = (<HTMLIFrameElement>frameContent).contentWindow;
    const iDocument = iWindow.document;
    const element = iDocument.getElementById('was');
    let data1 = JSON.parse(localStorage.getItem('annotation_html'));

    // this.dialogRef.close(data);

    if (data1 !== null) {
      if (data1[0].markers.length === this.t.length) {
        let i = 1
        this.t.value.map((s: any) => {
          if (s.body_part !== null && s.damage_property !== null && s.damage_serverity !== null && s.damage_type !== null && s.internal_damage !== null) {
            if (this.t.value.length === i) {
              this.data_list = { data: data1, obj: this.t.value };
              this.dialogRef.close(this.data_list);
            }
          } else {
            this.CommonService.SwalError('Please Select All fields', 'Error')
          }
          i = i + 1;
        })
      } else {
        this.CommonService.SwalError('Please Annotation & Part Details', 'Error')
      }
    } else {
      this.CommonService.SwalError('Please Select Annotation Part', 'Error')
    }

  }


  close() {
    this.dialogRef.close([]);
  }
  deleteRow(index: number) {
    this.t.removeAt(index);
  }
}
