import { BodyComponent } from './module/body/body.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { LayoutContainersModule } from './module/layout/layout.containers.module';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './services/shared.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { HeadroomModule } from '@ctrl/ngx-headroom';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { TokenInterceptorService } from './services/interceptor'
// import { WebcamModule } from 'ngx-webcam';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { AnnotationComponent } from './pages/annotation/annotation.component';
import { SafePipeModule } from './pipes/safe.module';
import { AnnotationPopupComponent } from './pages/annotation/annotation-popup/annotation-popup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true
    }),
    SharedModule,
    TabsModule.forRoot(),
    HeadroomModule,
    ScrollToModule.forRoot(),
    // WebcamModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSortModule,
    MatDialogModule,
    SafePipeModule,
    NgSelectModule,
    NgbModule,
    ModalModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    BodyComponent,
    AnnotationComponent,
    AnnotationPopupComponent,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
