import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit, OnDestroy {
  adminRoot = environment.adminRoot;
  constructor(private route:Router) { }
  ngOnInit(): void {
    document.body.classList.add('background');
  }
  ngOnDestroy(): void {
    document.body.classList.remove('background');
  }
}