import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { BodyComponent } from './module/body/body.component';
import { ErrorComponent } from './module/error/error.component';
import { UnauthorizedComponent } from './module/unauthorized/unauthorized.component';
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  // {
  //   path: '',
  //   component: BodyComponent,
  //   // canActivate: [AuthGuard],
  //   // canActivateChild: [AuthGuard],
  //   children: [
  //     { path: 'dashboard', loadChildren: () => import('./main/app/dashboard/dashboard.module').then(m => m.DashboardModule) },
  //     { path: 'myprofile', loadChildren: () => import('./main/app/myprofile/myprofile.module').then(m => m.MyprofileModule) },
  //   ]
  // },
  {
    path: '',
    component: BodyComponent,
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    children: [
      // { path: 'newinspection', loadChildren: () => import('./main/app/newinspection/newinspection.module').then(m => m.NewinspectionModule) },
      {
        path: 'uploaddocuments', loadChildren: () => import('./pages/newjob/newjob.module').then(m => m.NewjobModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'scandocument', loadChildren: () => import('./pages/sandocument/sandocument.module').then(m => m.SandocumentModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'vehicleinfo', loadChildren: () => import('./pages/vegicleinfo/vegicleinfo.module').then(m => m.VegicleinfoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'displayjobs', loadChildren: () => import('./pages/displayjobs/displayjobs.module').then(m => m.DisplayjobsModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'createjob', loadChildren: () => import('./pages/creatingjob/creatingjob.module').then(m => m.CreatingjobModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'damagepart', loadChildren: () => import('./pages/damagepart/damagepart.module').then(m => m.DamagepartModule),
        canActivate: [AuthGuard]
      },

      {
        path: 'all-details/:id', loadChildren: () => import('./pages/alldetails/alldetails.module').then(m => m.AlldetailsModule),
        canActivate: [AuthGuard]
      },

      {
        path: 'viewvehicleinfo/:Jobid', loadChildren: () => import('./pages/viewvehicleinfo/viewvehicleinfo.module').then(m => m.ViewvehicleinfoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'aivehicleinfo/:Jobid', loadChildren: () => import('./pages/aivehicleinfo/aivehicleinfo.module').then(m => m.AivehicleinfoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'viewjobdetails/:Jobid', loadChildren: () => import('./pages/viewjobdetails/viewjobdetails.module').then(m => m.ViewjobdetailsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jobassign', loadChildren: () => import('./pages/jobassign/jobassign.module').then(m => m.JobassignModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  { path: 'error', component: ErrorComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', redirectTo: '/error' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
