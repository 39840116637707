import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) { }
  path;
  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const currentUser = JSON.parse(localStorage.getItem('Role_id'));
    if (currentUser) {
      // if (route.data && route.data.roles) {
      //   if (route.data.roles.includes(currentUser.role)) {
      //     return true;
      //   } else {
      //     this.router.navigate(['/unauthorized']);
      //     return false;
      //   }
      // } else {
      //   return true;
      // }
      if (currentUser.client_id > 0) {
        return true;
      } else {
        this.router.navigate(['/unauthorized']);
      }
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const currentUser = JSON.parse(localStorage.getItem('Blockuser_Details'));
    this.path = route.url[0].path;
    const code = JSON.parse(localStorage.getItem('code'));
    const currentUser = JSON.parse(localStorage.getItem('Role_id'));
    if (currentUser) {
      if (currentUser[0].Role_id > 0) {
        return true;
      } else {
        this.router.navigate(['/unauthorized']);
      }
    } else {
      this.router.navigate(['/']);
      return false;
    }

    // if (code == 2 || code == 3) {
    //   if (this.path == 'uploaddocuments' || this.path == 'scandocument' || this.path == 'vehicleinfo' || this.path == 'createjob' || this.path == 'damagepart') {
    //     this.router.navigate(['/displayjobs']);
    //     return false
    //   }
    // }else{
    //   return true;
    // }

  }
}