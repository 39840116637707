import { environment } from 'src/environments/environment';

const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: any;

}
const Roles_list = { Admin: 'Admin', Editor: 'Editor' }
const data: IMenuItem[] = [
  {
    icon: 'iconsminds-shop-4',
    label: 'menu.dashboards',
    to: `/dashboard`,
    roles: [Roles_list.Admin, Roles_list.Editor],
    // subs: [
    //   {
    //     icon: 'simple-icon-paper-plane',
    //     label: 'menu.start',
    //     to: `/vien/start`,
    //     // roles: [UserRole.Admin],
    //   },
    // ],
  },
  {
    icon: 'iconsminds-digital-drawing',
    label: 'menu.myprofile',
    to: `/myprofile`,
    roles: [Roles_list.Admin, Roles_list.Editor],
    subs: [
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.start',
        to: `/myprofile`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.invoice',
        to: `/dashboard`,
        // roles: [UserRole.Admin],
      },
    ],
  },

  {
    icon: 'iconsminds-shop-4',
    label: 'menu.newinspection',
    to: `/newinspection`,
    roles: [Roles_list.Admin, Roles_list.Editor],
    // subs: [
    //   {
    //     icon: 'simple-icon-paper-plane',
    //     label: 'menu.start',
    //     to: `/vien/start`,
    //     // roles: [UserRole.Admin],
    //   },
    // ],
  },

];
export default data;
